<template>
  <div class="business">
    <img
      class="header-img"
      style="width: 100%;font-size:0"
      src="../../assets/imgs/company-header.png"
      alt=""
    />
    <page-header
      :tabs="tabs"
      name="业务板块"
      :active-tab="activeTab"
      @tab-change="tabChange"
    ></page-header>
    <router-view></router-view>
  </div>
</template>

<script>
import { TABS } from "./config";
import PageHeader from "../../components/PageHeader/PageHeader.vue";
export default {
  data() {
    return {
      tabs: TABS,
      activeTab: TABS[0],
    };
  },
  components: {
    PageHeader,
  },
  watch: {
    $route: {
      immediate: true,
      handler(v) {
        if (v && v.meta.name) {
          this.activeTab = v.meta.name;
        }
      },
    },
  },
  methods: {
    tabChange(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style scoped lang="less">
.header-img{
  padding-top: 140px;
}
</style>
